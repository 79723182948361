import * as icons from '@tabler/icons-vue';
import { IconStar } from '@tabler/icons-vue';

type IconKey = keyof typeof icons;

export async function loadIcon(iconName: string) {
  const validIconName = iconName as IconKey;

  if (validIconName in icons) {
    return icons[validIconName];
  } else {
    console.warn(`Icon "${iconName}" not found. Using fallback icon.`);
    
    return IconStar;
  }
}