<template lang="pug">
div
  component(
    v-if="iconComponent"
    :is="iconComponent"
    class="icon"
    :class="`icon--${theme}`"
    size="20"
  )
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { loadIcon } from '~/utils/loadIcon';

interface IProps {
  icon?: string;
  theme?: string;
}

const props = withDefaults(defineProps<IProps>(), {
  theme: "primary",
});

const iconComponent = ref<any>(null);

onMounted(async () => {
  if (!props.icon) return;

  iconComponent.value = await loadIcon(props.icon);
});
</script>

<style lang="sass" scoped>
.icon
  width: 20px
  height: 20px
  min-width: 20px

  &--primary
    stroke: var(--tealgreen-900, #00261E)

  &--secondary
    stroke: var(--white, #FFFFFF)
</style>